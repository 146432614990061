.App-header {
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.3);
    padding: 0;
	position: fixed;
	top: 0;
	width: 100%;
 }

  .logo-img {
    max-height: 100px;
  }

  .store-map {
	border: #f3bd14 3px solid;
  }
  
  .about-us {
	background: #fff;
	color: #000;
	border-top: #f3bd14 5px solid;
  }
  .about-us-header {
    font-weight: bold;
  }

  .footer-seal {
    background-color: rgba(247,247,247,1);
    padding: 15px 0;
    margin: -2px -2px 0 -2px;
    text-align: center;     
  }
  .footer-text {
    padding: 20px;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    text-align: center;
    margin-top: 8px;
    color: white;
  }

  .footer1 {
	display: none;
	font-size: 13px;
	color: rgba(255, 255, 255, 0.6);
	font-family: 'Open Sans', Arial, Helvetica, sans-serif;
	line-height: 1.5;
	padding: 20px;

}

.footer2 {
	display: block;
	font-size: 13px;
	color: rgba(255, 255, 255, 0.6);
	font-family: 'Open Sans', Arial, Helvetica, sans-serif;
	line-height: 1.5;
	padding: 20px;
}

.footer2 a {
	color: #aed2ec;
}

.footer2-text {
	font-family: 'Source Sans Pro';
	font-size: 12px;
	text-align: justify;
	color: white;
	background-color: #1d2755;
	padding: 10px 20px 10px 20px;
}

.footer2-text a {
	font-family: 'Source Sans Pro';
	font-size: 12px;
	color: #aed2ec;
}

.store-details {
	background: #fff;
	color: #000;
}
.store-details a {
	color: #0d6efd;
	text-decoration: none;
}
.store-details a:hover {
	color: #f3bd14;
}

.embed-responsive {
	width: 100%;
}

video {
	height: 100vh;
	width: 100%;
}

.footer-details {
	background: #323232;
}

.customer-phone {
	color: #0d6efd;
}

.main-body {
	width: 100%;
	font-size: 16px;
	padding-top: 110px;
}

.img-girl {
	display: none;
}
.img-20years {
	display: none;
}

.process-title {
	padding: 30px 0 20px 0;
}

@media ( min-width : 768px) and (max-width: 1000px) {
	.container {
		width: 100%
	}
}

@media only screen and (min-width: 1000px) and
	(-webkit-min-device-pixel-ratio: 1) {
	.process-title {
		padding: 70px 0 30px 0;
	}
	.footer1 {
		display: block;
	}
	.footer2 {
		display: none;
	}
	.main-body {
		position: relative;
		height: 430px;
		font-size: 16px;
	}
	.sticky {
		position: fixed;
		top: 0;
		width: 100%
	}
	.img-girl {
		display: block;
	}
	.img-girl img {
		width: 300px;
		height: 300px;
	}
	.img-20years {
		display: block;
		padding-top: 90px;
	}
}

.footer-menu {
	text-align: left;
}

.footer-menu a {
	color: rgba(255, 255, 255, 0.6);
	text-decoration: none;
	padding-left: 20px;
}

.footer-menu a:hover {
	color: rgba(255, 255, 255, 0.9);
}

a.socialmedia-link {
	font-size: 20px;
	color: rgba(255, 255, 255, 0.6);
	text-decoration: none;
	padding-left: 20px;
}
a.socialmedia-link:hover {
	color: rgba(255, 255, 255, 0.9);
}

.ola {
	width: 130px;
	left: 15px;
	top: -225px;
	z-index: 2;
	position: relative;
}

.ola-banner {
	z-index: 1;
	position: inherit;
	left: 0;
}

.footer-link {
	color: #aed2ec;
	text-wrap: nowrap;
}

.footer-link:hover {
	color: #aed2ec;
}

.disclaimer-text {
    margin-left: -160px;
    padding: 40px 20px 60px 0;
    color: #fff;
    text-align: justify;
}
.footer1-text {
    padding: 40px 0 0 0;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    text-align: center;
    margin-top: 8px; color: white;    
}
.unsubscribe-panel {
	min-height: 400px;
}